import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import React from "react"

export default () => {
  return (
    <Page pageTitle="404" pageDescription="Deze pagina is niet gevonden">
        <div className="vh-100 d-flex align-items-center">
            <h1 className="mx-auto">De pagina die u zocht kan niet worden gevonden</h1>
        </div>
    </Page>
  )
}